<!-- V1.0 base version updated by Raja Mohamed on 18-Sep-20
1.using activity module the sysadmin can add/edit/delete the activities.-->
<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>

    <v-data-table
      :headers="headers"
      item-key="uid"
      :items="todos"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="name"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <!--<DataTable :headers="headers" :items="todos" :loading="notloaded" :items-per-page="roles.itemsPerPage" :sorttype="'name'" :editflag="editflag"> -->
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Activities</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD ACTIVITY</v-btn
              ><!-- add button to add activity-->
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                    <v-row
                      ><!-- common for add and edit -->
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="inputrequired"
                          label="Name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-select
                          v-model="editedItem.status"
                          :rules="inputrequired"
                          :items="taskstatus"
                          label="Status"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <DatePicker
                          :menu="menuls"
                          :datevalue="editedItem.lastService"
                          :datelabeltext="'Last Service Date'"
                          @dateassinged="editedItem.lastService = $event"
                        />
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <DatePicker
                          :menu="menuns"
                          :datevalue="editedItem.nextService"
                          :datelabeltext="'Next Service Date'"
                          @dateassinged="editedItem.nextService = $event"
                          :rules="inputrequired"
                        />
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-text-field
                          v-model="editedItem.comments"
                          :rules="inputrequired"
                          label="Comments"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" text :disabled="!valid" @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- the below to convert the date into readable format for data-table-->
      <template v-slot:[`item.nextService`]="{ item }">
        {{ convertDate(item.nextService) }}
      </template>

      <template v-slot:[`item.lastService`]="{ item }">
        {{ convertDate(item.lastService) }}
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Activity</h2></div>
      </template>
    </v-data-table>
    <!--</DataTable>-->
  </v-container>
</template>
<script>
import { activityRef } from "@/fb"
import { convertAll } from "@/mixins/convertall.js"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import DatePicker from "@/components/common/Datepicker.vue"
import Spinner from "@/components/common/spinner.vue"
import {mapState} from "vuex"
//import DataTable from "@/components/common/DataTable.vue"
export default {
  name: "Activity",
  // props:['roles'],
  mixins: [convertAll, collectionsOperation], // for confirmation while delete
  components: { Snackbar, Alertmsg, DatePicker, Spinner },
  data: () => ({
    dialog: false,
    valid: true,
    lazy: false,
    menuls: false, // lastService date
    menuns: false, // nextService date
    search: "", // for data table search
    domain: "",
    notloaded: true, // will be made false once the data-table items fetched from db. till that time loading please wait message will be shown to the user
    headers: [
      // data table headers
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "LastService", value: "lastService" },
      { text: "NextService", value: "nextService" },
      { text: "Status", value: "status" },
      { text: "Comments", value: "comments" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    inputrequired: [(v) => !!v || "Input is required"],
    todos: [], // activies array
    editedIndex: -1,
    editedItem: {
      name: "",
      lastService: "",
      nextService: "",
      status: "",
      comments: "",
      domain: "",
    },
    defaultItem: {
      name: "",
      lastService: "",
      nextService: "",
      status: "",
      comments: "",
      domain: "",
    },
    taskstatus: ["OnGoing", "Pending", "Completed", "NotStarted"],

    editflag: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    snackbar: false,
    roles: "",
    initializeflag: true,
    spinner: false,
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Activity" : "Edit Activity"
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile
    console.log("Activity crated", this.roles)
    if (this.roles) {
      if (this.roles.role.sysadmin || this.roles.role.readuser) {
        if (this.roles.role.sysadmin) {
          // only sysadmin can have access to this page.
          this.editflag = true
        }

        this.domain = this.roles.domain

        await this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        this.$router.push({ name: this.roles.menufrom, params: { roles: this.roles } })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

  mounted() {
    activityRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
          if(!this.notloaded)
          this.updaterealtimedata(this.todos, change)
       
      }) // end of for each
    }) // end of snapshot
  }, // end of mounted

  methods: {
    dateassinged(option, value) {
      if (option == "Last Service Date") this.editedItem.lastService = value
      else if (option == "Next Service Date") this.editedItem.nextService = value
    },
    async initialize() {
      ;(this.todos = []),
        await activityRef
          .where("domain", "==", this.domain)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              var objIndex = this.todos.findIndex((obj) => obj.uid == doc.id)
              if (objIndex < 0)
                this.todos.push({
                  ...doc.data(),
                  uid: doc.id,
                })
            })
            this.notloaded = false
          })
    },

    editItem(item) {
      this.editedIndex = this.todos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      console.log("inside editItem", this.editedItem)
      this.dialog = true
      this.snackbar = false
    },
    
  

    async deleteItem(item) {
      const deletemsg = "Are you sure you want to delete this activity?" + item.name
      var Ret = await this.commonDeleteItem(item, this.todos, activityRef, deletemsg)
      console.log("Return from delete Item", Ret)
      if (Ret == true) {
        this.snackbar = true
        this.snackbartext = "The activity has been deleted successfully"
      } else {
        this.alertflag = true
        this.alertmsg = "Error while delete" + Ret
      }
    },

    close() {
      this.dialog = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedIndex > -1) {
        Object.assign(this.todos[this.editedIndex], this.editedItem)
        console.log("inside saveitem update", this.editedItem)
        try {
          await activityRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })
          ;(this.snackbar = true), (this.snackbartext = "Activity updated successfully")
          this.close()
        } catch (error) {
          ;(this.alertflag = true), (this.alertmsg = "Error in  update activity")
          console.log("Error while update :", error)
          this.spinner = false
        }
      } else {
        this.editedItem.domain = this.domain
        console.log("inside new item add", this.editedItem)
        var tmprecord = this.todos.filter((re) => re.name == this.editedItem.name && re.status != "Completed")
        if (tmprecord.length == 0) {
          try {
            await activityRef
              .add(this.editedItem)
              .then((docRef) => {
                this.editedItem.uid = docRef.id
              })
              .catch((error) => console.error("Error adding Ativity: ", error))
            var objIndex = this.todos.findIndex((obj) => obj.uid == this.editedItem.uid)
            if (objIndex < 0) this.todos.push(this.editedItem)
            ;(this.snackbar = true), (this.snackbartext = "New activity has been added successfully")
            this.close()
          } catch (error) {
            console.log("error in add new activity", error)
            this.alertflag = true
            this.alertmsg = "Error in add new activity"
            this.spinner = false
          }
        } // end of queryresult=0
        else {
          console.log("The actvity already exists", this.editedItem.name)
          this.alertflag = true
          this.alertmsg = "The Actvity name already exists"
          this.spinner = false
        } // end of else
      }
    },
  },
}
</script>
